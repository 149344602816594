import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
  state: () => ({
    id: parseInt(localStorage.getItem("id")) || null,
    displayname: localStorage.getItem("displayname") || null,
    firstname: localStorage.getItem("firstname") || null,
    lastname: localStorage.getItem("lastname") || null,
  }),
  getters: {
    getId(state) {
      return state.id;
    },
    getDisplayname(state) {
      return state.displayname;
    },
    getFirstname(state) {
      return state.firstname;
    },
    getLastname(state) {
      return state.lastname;
    },
    isUserSet(state) {
      if (state.id && state.displayname) {
        console.log("User is set");
        return true;
      }
      console.log("User is not set");
      return false;
    },
  },
  actions: {
    setUserOnLogin(displayname, id, token) {
      localStorage.setItem("id", id);
      localStorage.setItem("displayname", displayname);
      localStorage.setItem("token", token);

      // Split the displayname into first name and last name
      const [firstname, ...lastnameArray] = displayname.split(" ");
      const lastname = lastnameArray.join(" ");

      localStorage.setItem("firstname", firstname);
      localStorage.setItem("lastname", lastname);
    },
    logout() {
      localStorage.removeItem("id");
      localStorage.removeItem("displayname");
      localStorage.removeItem("firstname");
      localStorage.removeItem("lastname");
      // You may also want to reset the state in-memory
      this.$reset();
    },
  },
});
