import axios from "axios";

const ENDPOINT = process.env.VUE_APP_API_ENDPOINT;

const api = axios.create({
  timeout: 5000,
});

function setToken() {
  return localStorage.getItem("token");
}

/**
 * Log user out from backend
 */
export async function userLogOut() {
  api.defaults.headers.common["Authorization"] = `Bearer ${setToken()}`;
  return await api.post(`${ENDPOINT}/user/logout`);
}
export async function createUser(payload) {
  api.defaults.headers.common["Authorization"] = `Bearer ${setToken()}`;
  return await api.post(`${ENDPOINT}/user/register`, payload);
}

export async function attemptLogin(payload) {
  api.defaults.headers.common["Authorization"] = `Bearer ${setToken()}`;
  return await api.post(`${ENDPOINT}/user/login`, payload);
}

/**
 * Dashboard
 * @returns object
 */
export async function getSimpleStats() {
  api.defaults.headers.common["Authorization"] = `Bearer ${setToken()}`;
  return await api.get(`${ENDPOINT}/api/dashboard/getSimpleStats`);
}
