<script setup>
import { useAppStore } from "@/stores/app";
import { useUserStore } from "@/stores/user";
import { userLogOut } from "@/api/backend.js";
import { useRouter } from "vue-router";
const userStore = useUserStore();
const appStore = useAppStore();

const router = useRouter();
function toggle() {
  appStore.toggleDrawer();
}

async function logout() {
  const response = await userLogOut();
  userStore.logout();
  router.push({
    name: "Login",
  });
  appStore.setSnackbar({
    text: "Uloskirjautuminen onnistui",
    type: "succes",
  });
  console.log(response);
}
</script>
<template>
  <v-app-bar class="bg-primary">
    <v-btn icon @click="toggle"><v-icon size="x-large">mdi-menu</v-icon></v-btn>
    <v-app-bar-title class="mx-0 px-0">LEADIFIX </v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn @click="logout" icon="mdi-logout"></v-btn>
  </v-app-bar>
</template>
