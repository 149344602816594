<script setup>
import { computed } from "vue";
import { useAppStore } from "@/stores/app";

const useStore = useAppStore();
const bgcolor = computed(() => {
  switch (useStore.snackbar.type) {
    case "success":
      return "success";
    case "error":
      return "error";
    default:
      return "grey";
  }
});

const icon = computed(() => {
  switch (useStore.snackbar.type) {
    case "success":
      return "mdi-emoticon-outline";
    case "error":
      return "mdi-emoticon-dead-outline";
    default:
      return "mdi-information-outline";
  }
});
</script>

<template>
  <v-snackbar
    rounded="lg"
    class="pa-0"
    :color="bgcolor"
    v-model="useStore.snackbar.state"
  >
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <v-icon
        v-if="!useStore.snackbar.btnText || !useStore.snackbar.btnFunc"
        size="x-large"
        >{{ icon }}</v-icon
      >
      <div style="min-width: 80%">
        <span style="width: 100% !important" class="mx-2 d-block">{{
          useStore.snackbar.text
        }}</span>
        <v-btn
          v-if="useStore.snackbar.btnText && useStore.snackbar.btnFunc"
          class="d-block ml-3"
          block
          @click="useStore.snackbar.btnFunc"
        >
          {{ useStore.snackbar.btnText }}</v-btn
        >
      </div>

      <v-btn
        outlined
        icon
        small
        density="compact"
        elevation="0"
        class="pa-0 ma-0 bg-transparent text-primary"
        @click="useStore.snackbar.state = false"
      >
        <v-icon class="text-white pa-0 ma-0">mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>
