import { useUserStore } from "@/stores/user";
import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: `/`,
      name: "Login",
      component: () => import("../views/LoginView.vue"),
      meta: {
        requireAuth: false,
        roles: [],
      },
    },
    {
      path: `/login`,
      name: "Login",
      component: () => import("../views/LoginView.vue"),
      meta: {
        requireAuth: false,
        roles: [],
      },
    },
    {
      path: `/dashboard`,
      name: "Dashboard",
      component: () => import("../views/DashboardView.vue"),
      meta: {
        requireAuth: false,
        roles: [],
      },
    },
    {
      path: `/commonSettings`,
      name: "CommonSettings",
      component: () => import("../views/CommonSettingsView.vue"),
      meta: {
        requireAuth: false,
        roles: [],
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // This route requires auth, check if logged in
    // if not, redirect to login page.
    if (!useUserStore().isUserSet) {
      console.log("Route requires auth, but user is not set");
      return next({
        name: "Login", // name of the login route
      });
    }
  } else {
    // Route requires no auth, proceed as usual
    return next();
  }
});

export default router;
