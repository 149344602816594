<script setup>
import { ref, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { useAppStore } from "@/stores/app";
import { useRouter } from "vue-router";

const router = useRouter();
const appStore = useAppStore();
const userStore = useUserStore();

const drawerState = ref(appStore.getDrawer);
// Function to toggle the drawer
function toggleDrawer() {
  appStore.toggleDrawer();
}

// Watch for changes in the store drawer state and update the local ref accordingly
watch(
  () => appStore.getDrawer,
  (newDrawerState) => {
    drawerState.value = newDrawerState;
  }
);

// Watch for changes in the local ref and update the store drawer state accordingly
watch(drawerState, (newDrawerState) => {
  if (newDrawerState !== appStore.getDrawer) {
    appStore.toggleDrawer;
  }
});
</script>

<template>
  <v-navigation-drawer v-model="drawerState">
    <v-list-item class="py-6" :title="userStore.getDisplayname">
      <template v-slot:prepend>
        <v-icon size="x-large" color="primary">mdi-account </v-icon>
      </template>
      <v-list-item-subtitle> ID: 22{{ userStore.id }}</v-list-item-subtitle>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item
      @click="router.push({ name: 'CommonSettings' }), toggleDrawer"
      class="py-6"
      link
      title="Yleiset asetukset"
    >
      <template v-slot:prepend>
        <v-icon size="x-large" color="primary">mdi-cog</v-icon>
      </template>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item class="py-6" link title="Mukauta teemaa">
      <template v-slot:prepend>
        <v-icon size="x-large" color="primary">mdi-palette</v-icon>
      </template>
    </v-list-item>
    <v-divider inset></v-divider>
    <v-list-item color="primary" class="py-6" link title="Tukipyyntö">
      <template v-slot:prepend>
        <v-icon size="x-large" color="primary">mdi-face-agent</v-icon>
      </template>
    </v-list-item>
    <v-divider inset></v-divider>
  </v-navigation-drawer>
</template>
