import { defineStore } from "pinia";

export const useAppStore = defineStore("app", {
  state: () => ({
    drawer: false,
    snackbar: {
      state: false,
      text: null,
      type: null,
      btnText: null,
      btnFunc: null,
    },
  }),
  getters: {
    getDrawer(state) {
      return state.drawer;
    },
  },
  actions: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    setSnackbar(data) {
      this.snackbar.state = true;
      if (!data.text) {
        if (data.type == "success") {
          this.snackbar.text = "Toiminto suoritettiin onnistuneesti";
        } else {
          this.snackbar.text = "Jokin meni vikaan";
        }
      } else {
        this.snackbar.text = data.text;
      }
      this.snackbar.type = data.type;
      this.snackbar.btnText = data.btnText;
      this.snackbar.btnFunc = data.btnFunc;
      setTimeout(() => {
        this.snackbar = {
          state: false,
          text: null,
          type: null,
          btnText: null,
          btnFunc: null,
        };
      }, 3000);
    },
    /**
     * Reset states to og state.
     */
    reset() {
      console.log("Reset store: app");
      this.overlay = false;
    },
  },
});
