<script setup>
import { onMounted } from "vue";
import { useUserStore } from "@/stores/user";
import { RouterView, useRouter } from "vue-router";
import BaseSnackBar from "./components/BaseSnackBar.vue";

import TheHeader from "./components/TheHeader.vue";
import TheNavDrawer from "./components/TheNavDrawer.vue";

const router = useRouter();
const userStore = useUserStore();

onMounted(() => {
  if (userStore.isUserSet !== true) {
    console.log("Route requires auth, but user is not set");
    router.push({
      name: "Login",
    });
  }
});
</script>

<template>
  <v-app>
    <v-main>
      <TheHeader></TheHeader>
      <TheNavDrawer></TheNavDrawer>
      <RouterView></RouterView>
    </v-main>
    <BaseSnackBar></BaseSnackBar>
  </v-app>
</template>
